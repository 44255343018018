/* Form.css */

.custom-booking-container {
    max-width: 600px;
    margin: auto;
    margin-top: 20px;
    padding: 30px;
    background-color: #e0f7fa;
    border-radius: 15px;
    border: 1px solid #ccc;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }
  
  .custom-booking-form {
    display: grid;
    gap: 20px;
  }
  
  .custom-input {
    width: 100%;
    height: 40px;
    padding: 12px;
    border: 1px solid #26a69a;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease-in-out;
  }
  
  .custom-input:focus {
    border-color: #1e7721;
  }
  
  .col-lg-6 {
    display: flex;
    flex-direction: column;
  }
  
  .custom-button {
    background-color: #26a69a;
    color: #fff;
    padding: 15px;
    border: none;
    width: 160px;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .custom-button:hover {
    background-color: #00796b;
  }
  .calendar-popup{
    margin-top: -280px;
    margin-left: 270px;
  }
  
  .row::after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Additional styling for labels */
  label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  /* Additional styling for responsiveness */
  @media (max-width: 768px) {
    .col-lg-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  