/* ChangePassword.css */

.password-change-form {
  max-width: 400px;
  /* font-family: 'calibri'; */
  margin-top: 10px;
  margin-left: 33%; /* Adjusted margin for centering */
  padding: 30px;
  border: 1px solid #ccc;
  /* background: url('fabro/src/admin/img/pexels-pixabay-459277.jpg'); */
  border-radius: 5px;
  box-shadow: 10px 10px 10px 5px lightcoral;
  background-color: #fff;
}

.form-group {
  margin-bottom: 15px;
}

.input {
  width: 100%;
  padding: 10px; /* Adjusted padding */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
  height: auto;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.change {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px; /* Adjusted padding */
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}
