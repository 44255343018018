.booked-details-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
  }
  
  .details-container {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 80%;
    max-width: 600px;
    text-align: left; /* Align text to the left */
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #3498db;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  }
  
  .details-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 120px;
    text-decoration: underline;
    color:  #3498db;
  }
  
  .booking-info {
    margin-top: 50px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .info-row {
    flex-basis: calc(50% - 10px);
    margin-bottom: 10px;
    display: flex;
  }
  
  .label {
    font-weight: bold;
    flex-basis: 50%;
    text-align: left;
    color: rgb(37, 34, 34);
    margin-left: 40px;
  }
  
  .value {
    flex-basis: 30%;
    text-align: left;
    color: #2c3e50;
    margin-left: 20px;
  }
  
  .loading,
  .error,
  .no-details {
    font-size: 18px;
    color: #e74c3c;
  }
  
  .loading {
    margin-top: 20px;
    text-align: center;
  }
  
  .error,
  .no-details {
    margin-top: 40px;
    text-align: center;
  }
  
  /* Add a bit of animation */
  .booked-details-container {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Add a subtle hover effect on the details-container */
  .details-container:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  }