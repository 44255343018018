/* Gallery.css */

.gallery-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .gallery-item {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
    object-fit: cover;

    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .gallery-item .gi-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    padding: 15px;
    text-align: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .section-title {
    margin-bottom: 30px;
  }
  