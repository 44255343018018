/**
* Template Name: MeFamily
* Updated: Sep 18 2023 with Bootstrap v5.3.2
* Template URL: https://bootstrapmade.com/family-multipurpose-html-bootstrap-template-free/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/


body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #006fbe;
  text-decoration: none;
}

a:hover {
  color: #008df1;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #006fbe;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #0087e7;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  box-shadow: 0px 2px 15px rgba(36, 50, 93, 0.06);
}

#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.5px;
}

#header .logo a {
  color: #24325d;
}

#header .logo img {
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #2b3c6f;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #006fbe;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #006fbe;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #24325d;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(22, 30, 56, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #24325d;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #006fbe;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #006fbe;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 80vh;
  background-color: rgba(22, 30, 56, 0.8);
  overflow: hidden;
  position: relative;
}

#hero .carousel,
#hero .carousel-inner,
#hero .carousel-item,
#hero .carousel-item::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 70px;
  left: 50px;
  right: 50px;
}

#hero .container {
  text-align: center;
}

#hero h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 48px;
  font-weight: 700;
}

#hero p {
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 30px;
  line-height: 0;
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.5);
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hero .carousel-control-next-icon:hover,
#hero .carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

#hero .carousel-indicators li {
  list-style-type: none;
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: 0.6;
  transition: 0.3s;
}

#hero .carousel-indicators li.active {
  opacity: 1;
  background: #006fbe;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #fff;
  animation-delay: 0.8s;
  background: #006fbe;
}

#hero .btn-get-started:hover {
  background: #007ed8;
}

@media (max-width: 992px) {
  #hero {
    height: 100vh;
  }

  #hero .carousel-container {
    top: 8px;
  }
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 28px;
  }
}

@media (min-width: 1024px) {

  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 45px 0;
}

.section-bg {
  background-color: #b4e0ff;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;

}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #006fbe;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# My & Family
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li+li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #006fbe;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 5px;
  transition: 0.3s;
  line-height: 1;
  color: #006fbe;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #006fbe;
}

.about .content .btn-learn-more:hover {
  background: #006fbe;
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .icon-box {
  margin-bottom: 20px;
  text-align: center;
}

.features .icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 50%;
  margin-bottom: 20px;
  transition: 0.5s;
  overflow: hidden;
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15);
}

.features .icon i {
  line-height: 0;
  color: #006fbe;
  font-size: 34px;
}

.features .icon-box:hover .icon {
  box-shadow: 0px 0 25px rgba(0, 111, 190, 0.3);
}

.features .title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
  position: relative;
  padding-bottom: 15px;
}

.features .title a {
  color: #444444;
  transition: 0.3s;
}

.features .title a:hover {
  color: #006fbe;
}

.features .title::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 2px;
  background: #006fbe;
  bottom: 0;
  left: calc(50% - 25px);
}

.features .description {
  line-height: 24px;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Recent Photos
--------------------------------------------------------------*/
.recent-photos {
  overflow: hidden;
}

.recent-photos .swiper-pagination {
  margin-top: 10px;
  position: relative;
}
.recent-photos-slider {
  position: relative;
}

.swiper-pagination {
  position: relative;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.swiper-pagination-bullet {
  margin: 0 5px;
  width: 10px;
  height: 10px;
  background-color: #fff;
  opacity: 0.7;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.recent-photos .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #006fbe;
}

.recent-photos .owl-nav,
.recent-photos .owl-dots {
  margin-top: 25px;
  text-align: center;
}

.recent-photos .owl-item {
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

.recent-photos .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #95d3ff !important;
}

.recent-photos .owl-dot.active {
  background-color: #006fbe !important;
}

.recent-photos .gallery-carousel .owl-stage-outer {
  overflow: visible;
}

.recent-photos .gallery-carousel .center {
  border: 6px solid #006fbe;
  margin: -10px;
  box-sizing: content-box;
  padding: 4px;
  background: #fff;
  z-index: 1;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #f5f7fb;
  min-height: 40px;
  margin-top: 70px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 58px;
  }
}

.breadcrumbs h2 {
  font-size: 28px;
  margin-bottom: 0;
  font-weight: 300;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #324682;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Story Intro
--------------------------------------------------------------*/
.story-intro .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.story-intro .content ul {
  list-style: none;
  padding: 0;
}

.story-intro .content ul li {
  padding-bottom: 10px;
}

.story-intro .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #006fbe;
}

.story-intro .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Featured Members
--------------------------------------------------------------*/
.featured-members .content+.content {
  margin-top: 100px;
}

.featured-members .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.featured-members .content ul {
  list-style: none;
  padding: 0;
}

.featured-members .content ul li {
  padding-bottom: 10px;
}

.featured-members .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #006fbe;
}

.featured-members .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Members
--------------------------------------------------------------*/
.members {
  background: #fff;
  padding: 60px 0;
}

.members .member {
  margin-bottom: 20px;
  overflow: hidden;
}

.members .member .member-img {
  position: relative;
  overflow: hidden;
}

.members .member .social {
  position: absolute;
  left: 0;
  bottom: -40px;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: bottom ease-in-out 0.4s;
  text-align: center;
  background: rgba(0, 111, 190, 0.8);
}

.members .member .social a {
  transition: color 0.3s;
  color: #fff;
  margin: 0 10px;
  padding-top: 8px;
  display: inline-block;
}

.members .member .social a:hover {
  color: #006fbe;
}

.members .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.members .member .member-info h4 {
  font-weight: 700;
  margin: 15px 0 5px 0;
  font-size: 18px;
}

.members .member .member-info span {
  display: block;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 15px;
  color: #006fbe;
}

.members .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.members .member:hover .social {
  bottom: 0;
  opacity: 1;
  transition: bottom ease-in-out 0.4s;
}

/*--------------------------------------------------------------
# Event List
--------------------------------------------------------------*/
.event-list .card {
  border: 0;
  padding: 0 30px;
  margin-bottom: 60px;
  position: relative;
}

.event-list .card-img {
  width: calc(100% + 60px);
  margin-left: -30px;
  overflow: hidden;
  z-index: 9;
  border-radius: 0;
}

.event-list .card-img img {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}

.event-list .card-body {
  z-index: 10;
  background: #fff;
  border-top: 4px solid #fff;
  padding: 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin-top: -60px;
  transition: 0.3s;
}

.event-list .card-title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  color: #24325d;
}

.event-list .card-title a {
  color: #24325d;
  transition: 0.3s;
}

.event-list .card-text {
  color: #5e5e5e;
}

.event-list .read-more a {
  color: #777777;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  transition: 0.3s;
}

.event-list .read-more a:hover {
  color: #006fbe;
}

.event-list .card:hover img {
  transform: scale(1.1);
}

.event-list .card:hover .card-body {
  border-color: #006fbe;
}

.event-list .card:hover .card-body .card-title a {
  color: #006fbe;
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery .gallery-item {
  margin-bottom: 30px;
}

.gallery #gallery-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
}

.gallery #gallery-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s;
}

.gallery #gallery-flters li:hover,
.gallery #gallery-flters li.filter-active {
  color: #006fbe;
}

.gallery #gallery-flters li:last-child {
  margin-right: 0;
}

.gallery .gallery-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(36, 50, 93, 0.6);
}

.gallery .gallery-wrap::before {
  content: "";
  background: rgba(36, 50, 93, 0.6);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.gallery .gallery-wrap .gallery-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gallery .gallery-wrap .gallery-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.gallery .gallery-wrap .gallery-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.gallery .gallery-wrap .gallery-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.gallery .gallery-wrap .gallery-info p {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.gallery .gallery-wrap .gallery-links {
  text-align: center;
  z-index: 4;
}

.gallery .gallery-wrap .gallery-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.gallery .gallery-wrap .gallery-links a:hover {
  color: #25a4ff;
}

.gallery .gallery-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.gallery .gallery-wrap:hover .gallery-info {
  opacity: 1;
}

.gallery .gallery-wrap:hover .gallery-info::before {
  top: 15px;
  left: 15px;
}

.gallery .gallery-wrap:hover .gallery-info::after {
  bottom: 15px;
  right: 15px;
}

/*--------------------------------------------------------------
# Contact Us
--------------------------------------------------------------*/
.contact-us .info {
  width: 100%;
  background: #fff;
}

.contact-us .info i {
  font-size: 20px;
  color: #006fbe;
  float: left;
  width: 44px;
  height: 44px;
  background: #e7f5ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact-us .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #24325d;
}

.contact-us .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #405aa7;
}

.contact-us .info .email,
.contact-us .info .phone {
  margin-top: 40px;
}

.contact-us .info .email:hover i,
.contact-us .info .address:hover i,
.contact-us .info .phone:hover i {
  background: #006fbe;
  color: #fff;
}

.contact-us .php-email-form {
  width: 100%;
  background: #fff;
}

.contact-us .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact-us .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact-us .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact-us .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact-us .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact-us .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact-us .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact-us .php-email-form input,
.contact-us .php-email-form textarea {
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact-us .php-email-form input:focus,
.contact-us .php-email-form textarea:focus {
  border-color: #006fbe;
}

.contact-us .php-email-form input {
  height: 44px;
}

.contact-us .php-email-form textarea {
  padding: 10px 12px;
}

.contact-us .php-email-form button[type=submit] {
  background: #006fbe;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact-us .php-email-form button[type=submit]:hover {
  background: #007ed8;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #1d284b;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 30px 0;
}

#footer h3 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  position: relative;
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0 0 15px 0;
}

#footer p {
  font-size: 15;
  font-style: italic;
  padding: 0;
  margin: 0 0 40px 0;
}

#footer .social-links {
  margin: 0 0 40px 0;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #006fbe;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: #007ed8;
  color: #fff;
  text-decoration: none;
}

#footer .copyright {
  margin: 0 0 5px 0;
}

#footer .credits {
  font-size: 13px;
}

#footer .credits a {
  color: #006fbe;
  transition: 0.3s;
}

#footer .credits a:hover {
  color: #008df1;
}